<template>
    <u-dialog v-model="shows" title="Terms of Use" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid }" />
        </template>
        <div v-if="isLoaded">
            <!-- <tit-wrap-small>
                <div class="vw-100 w-sm-auto">
                    <v-checkbox v-model="isValid" label="I fully agree to the terms and conditions." readonly hide-details class="v-size--large" @click="toggleSelectedAll" />
                </div>
            </tit-wrap-small> -->
            <!-- <v-divider class="mt-8px" /> -->

            <template v-for="term in termsList">
                <page-section v-if="term.code == 'privacy-agree'" :key="term._id" class="page-section--xxs pb-0 pt-0">
                    <template #pageSectionFoot>
                        <!-- <tit-wrap-small>
                            <template #titleContents>
                                I agree with {{ term.subject }}.<span class="pl-4px" :class="[term.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "required" : "optional" }})</span>
                            </template>
                            <div class="vw-100 w-sm-auto">
                                <v-checkbox v-model="selected" :value="term._id" label="Agree" hide-details @click="toggleSelectedAll"/>
                            </div>
                        </tit-wrap-small> -->
                        <v-card outlined tile>
                            <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto;"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                        </v-card>
                    </template>
                </page-section>
            </template>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn x-large block outlined color="grey" @click="cancel">Cancel</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn x-large block color="primary" @click="agree">Agree</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/dumb/u-dialog.vue";
// import TitWrapSmall from "@/components/dumb/tit-wrap-small.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

export default {
    components: {
        UDialog,
        TitWrapSmall,
        PageSection,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            return !this.termsList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isValid) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
